<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-app id="inspire">
      <v-layout wrap justify-center class="login_page">
        <v-flex xs11 sm6 md5 lg4 px-2 align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs112 xl10>
              <v-snackbar
                v-model="showSnackBar"
                color="#9b221f "
                right
                :timeout="timeout"
              >
                <v-layout wrap justify-center>
                  <v-flex text-left class="align-self-center">
                    <span style="color: #FFFFFF">
                      {{ msg }}
                    </span>
                  </v-flex>
                  <v-flex text-right>
                    <v-btn
                      small
                      :ripple="false"
                      text
                      @click="showSnackBar = false"
                    >
                      <v-icon style="color: #FFF">mdi-close</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-snackbar>
              <v-card tile flat>
                <v-layout wrap justify-center>
                  <v-flex px-8 py-6>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                           <v-img height="120px" contain :src="require('./../../assets/logo.png')"></v-img>
                     
                      </v-flex>
                      <v-flex xs12 py-4 style="cursor: pointer">
                        <router-link to="/">
                          <span
                            style="
                              font-family: poppinsbold;
                              font-size: 20px;
                              cursor: pointer;
                              color: #545454;
                            "
                          >
                            Indiansnakes - Indian Snakebite Initiative
                            </span>
                        </router-link>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs6 lg4 px-2>
                            <span
                              style="
                                font-family: poppinssemibold;
                                font-size: 15px;
                                color: #000;
                              "
                            >
                              Log In
                            </span>
                            <v-progress-linear
                              height="2"
                              value="100"
                              color="#9b221f "
                            ></v-progress-linear>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex pt-8 xs12 text-left>
                        <v-layout wrap justify-center pa-2>
                          <!-- <v-flex xs12 pb-2>
                            <span
                              style="
                                font-family: ;
                                font-size: 12px;
                                color: #ababab;
                              "
                            >
                              Email
                            </span>
                          </v-flex> -->
                          <v-flex xs12>
                            <v-form @submit.prevent="validateInput">
                              <v-text-field
                                color="#717171"
                                placeholder="Username"
                                label="Username"
                                outlined
                                dense
                                class="rounded-xl"
                                v-model="email"
                                hide-details
                              >
                              </v-text-field>
                            </v-form>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-center pa-2>
                          <!-- <v-flex xs12 pb-2>
                            <span
                              style="
                                font-family: ;
                                font-size: 12px;
                                color: #ababab;
                              "
                            >
                              Password
                            </span>
                          </v-flex> -->
                          <v-flex xs12>
                            <v-form @submit.prevent="validateInput">
                              <v-text-field
                                color="#717171"
                                placeholder="Password"
                                label="Password"
                                type="password"
                                class="rounded-xl"
                                outlined
                                dense
                                v-model="password"
                                hide-details
                              >
                              </v-text-field>
                            </v-form>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <!-- <v-flex pt-2 xs12 text-left>
                        <router-link to="/ForgotPassword">
                          <span
                            style="
                              font-family: poppinssemibold;
                              font-size: 12px;
                              text-transform: none;
                            "
                          >
                            Forgot Password ?
                          </span>
                        </router-link>
                      </v-flex> -->
                      <v-flex xs12 py-6 pa-2>
                        <v-btn
                          block
                          tile
                          color="#9b221f "
                          light
                          :ripple="false"
                          depressed
                          @click="validateInput"
                          class="itemValue"
                        >
                         <span style="color:#FFFFFF"> Continue</span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      email: null,
      password: null,
      userType: "Admin",
      accessType: "signin",
      userTypes: ["Admin"],
    };
  },
  methods: {
    validateInput() {
      if (!this.email) {
        this.msg = "Please Provide Email";
        this.showSnackBar = true;
        return;
      } else if (!this.password) {
        this.msg = "Please Provide Password";
        this.showSnackBar = true;
        return;
      } else {
        this.login();
      }
    },
    login() {
      var userData = {};
      userData["accessType"] = this.accessType;
      userData["phone"] = this.email;
      userData["password"] = this.password;
      this.appLoading = true;
      // var url="/"+this.userType+"/"+this.accessType
      axios({
        method: "POST",
        url: "/user/signup/signinadmin",
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            store.commit("userType", response.data.role);
            store.commit("userData", response.data.userdata);
            store.commit("loginUser", response.data.token); 
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>